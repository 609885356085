import _ from "lodash";

const INITIAL_STATE = {
  list: null,
  single: null,
  listcount: null,
  weeks: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "WEEK_LIST": {
      return { ...state, weeks: action.payload };
    }
    case "TARGET_SINGLE": {
      const result = action.payload.target.map(x => {
        return Object.assign({}, x.target, {
          hoursOperation: x.hoursOperation,
          capSetting: x.capSetting
        });
      });

      return { ...state, single: result };
    }
    case "TARGET_LIST": {
      const result = action.payload.target.map(x => {
        return Object.assign({}, x.target, {
          hoursOperation: x.hoursOperation,
          capSetting: x.capSetting
        });
      });
      return { ...state, list: result, listcount: action.payload.total_data };
    }
    default: {
      return state;
    }
  }
}
