import { combineReducers } from "redux";
import auth from "./auth";
import loader from "./loader";
import account from "./account";
import campaign from "./campaign";
import didNumber from "./didNumber";
import buyer from "./buyer";
import target from "./target";
export default combineReducers({
  auth,
  account,
  loader,
  campaign,
  didNumber,
  buyer,
  target
});
