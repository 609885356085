import _ from "lodash";
const INITIAL_STATE = {
  list: null,
  single: null,
  buyer: null,
  extlist: null
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "CAMPAIGN_LIST": {
      return { ...state, list: action.payload };
    }
    case "DELETE_CAMPAIGN": {
      return {
        ...state,
        list: [...state.list.filter(x => x.QueueID !== action.payload)]
      };
    }
    case "INDIVIDUAL_CAMPAIGN": {
      return { ...state, single: action.payload };
    }
    case "BUYER_LIST_CAMPAIGN": {
      return { ...state, buyer: action.payload };
    }

    case "EXTENSION_LIST_BUYER": {
      return { ...state, extlist: action.payload };
    }

    case "UPDATE_CAMPAIGN": {
      return {
        ...state,
        list: _.unionBy([action.payload.campaign], state.list, "QueueID")
      };
    }
    default: {
      return state;
    }
  }
}
