import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AsyncLoadable from "@/utils/AsyncLoadable";
import "animate.css";
import "./style/base.less";
import "./style/App.less";
import "./style/customtheme.less";

const DefaultLayout = AsyncLoadable(() =>
  import(/* webpackChunkName: 'default' */ "./containers")
);
const View404 = AsyncLoadable(() =>
  import(/* webpackChunkName: '404' */ "./views/Others/404")
);
const View500 = AsyncLoadable(() =>
  import(/* webpackChunkName: '500' */ "./views/Others/500")
);
const Login = AsyncLoadable(() =>
  import(/* webpackChunkName: 'login' */ "./pages/Login")
);

const App = () => (
  <Switch>
    <Route path={`/`} exact render={() => <Redirect to={`/login`} />} />
    <Route path={`/500`} component={View500} />
    <Route path={`/login`} component={Login} />
    <Route path={`/404`} component={View404} />
    <Route component={DefaultLayout} />
  </Switch>
);

export default App;
