import _ from "lodash";
const INITIAL_STATE = {
  list: null,
  single: null,
  buyer: null
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "BUYER_LIST": {
      return { ...state, list: action.payload };
    }
    case "ADD_BUYER": {
      return {
        ...state,
        list: [...state.list, action.payload]
      };
    }
    case "UPDATE_BUYER": {
      return {
        ...state,
        list: _.unionBy([action.payload], state.list, "id")
      };
    }
    case "DELETE_BUYER": {
      return {
        ...state,
        list: [...state.list.filter(x => x.id !== action.payload)]
      };
    }
    case "ACTIVATE_BUYER": {
      return {
        ...state,
        buyer: _.unionBy([action.payload], state.buyer, "id")
      };
    }
    case "DEACTIVATE_BUYER": {
      return {
        ...state,
        buyer: _.unionBy([action.payload], state.buyer, "uniqueid")
      };
    }

    default: {
      return state;
    }
  }
}
